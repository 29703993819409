import axios from "axios";
import { SearchActivity, SearchActivityResult } from "../common/api";

export const getActivity = async (token) => {
  try {
    const response = await axios.get(SearchActivity);
    return response;
  } catch (error) {
    throw new Error("Error getting Search Event:", error);
  }
};

export const getActivityResult = async (event_name) => {
  try {
    const response = await axios.post(SearchActivityResult, {
      event_name: event_name,
    });
    return response;
  } catch (error) {
    throw new Error("Error getting Search Event:", error);
  }
};
