import React, { useEffect } from "react";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Compact = ({
  visiblePitches,
  newTimeWithslots,
  handleBoxClick,
  selectedPitchIcon,
  bookedPitches,
  formatDate,
  selectedDate,
  pitchWeekAvailability,
  singaporeTime,
  selectedPitch,
  BookingOptions,
  SelectedPitchName,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const formatTimeRangespace = (timeRange) => {
    if (timeRange) {
      const insertSpace = (time) => {
        return time.replace(/([AP]M)/, " $1");
      };

      const [start, end] = timeRange.split("-");
      const formattedStart = insertSpace(start);
      const formattedEnd = insertSpace(end);
      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const match = selectedPitch.match(/consecutive/);
  const extractedWord = match ? match[0] : null;
  // console.log(extractedWord);

  

  const isSlotAlreadyBooked = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return false;
    let temp = bookedPitches.some((item) => {
      const isSamePitch = item.custom_pitch === pitch.name;

      const isSameDate =
        formatDate(item.starts_on.split(" ")[0]) ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(
        time,
        formatTimeRange(item.starts_on, item.ends_on)
      );
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    return temp;
  };

  const isTimeInRange = (time, range) => {
    if (!range) return false;
    const timeMatch = time.match(/(\d+):(\d+) (AM|PM)/);

    if (timeMatch === null) {
      return false;
    }

    const [selectedStartHour, selectedStartMinute, selectedStartMeridiem] =
      timeMatch.slice(1);
    const [slotStartHour, slotStartMinute, slotStartMeridiem] = range
      .split("-")[0]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [slotEndHour, slotEndMinute, slotEndMeridiem] = range
      .split("-")[1]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    const convertTo24Hour = (hour, meridiem) => {
      if (hour === "12") {
        return meridiem === "AM" ? 0 : 12;
      }
      return parseInt(hour) + (meridiem === "PM" ? 12 : 0);
    };

    const selectedHour = convertTo24Hour(
      selectedStartHour,
      selectedStartMeridiem
    );
    const slotStart = convertTo24Hour(slotStartHour, slotStartMeridiem);
    const slotEnd = convertTo24Hour(slotEndHour, slotEndMeridiem);

    const selectedMinute = parseInt(selectedStartMinute);
    const slotStartMinuteInt = parseInt(slotStartMinute);
    const slotEndMinuteInt = parseInt(slotEndMinute);

    const selectedTotalMinutes = selectedHour * 60 + selectedMinute;

    if (slotStart <= slotEnd) {
      return (
        selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
        selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt
      );
    } else {
      return (
        (selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
          selectedTotalMinutes < 24 * 60) ||
        (selectedTotalMinutes >= 0 &&
          selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt)
      );
    }
  };

  const getSingaporeTimeAsDateObject = () => {
    // Get the current date and time in Singapore as a string
    let singaporeTimeString = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Singapore",
    });

    // Split the date and time parts
    let [datePart, timePart] = singaporeTimeString.split(", ");

    // Further split the date and time into components
    let [month, day, year] = datePart.split("/");
    let [hours, minutes, seconds] = timePart.split(/[: ]/).map(Number);
    let period = timePart.split(" ")[1];

    // Adjust hours for AM/PM
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Create a new Date object in the local time zone with the Singapore time components
    let singaporeDate = new Date(year, month - 1, day, hours, minutes, seconds);
    return singaporeDate;
  };

  const parseTimeToSingapore = (timeStr, referenceDate) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return referenceDate.set({ hour: hours, minute: minutes, second: 0 });
  };

  const isSlotDisabledByTodayTime = (
    slotTime,
    pitch,
    isWeekView = false,
    date = null
  ) => {
    if (!singaporeTime) {
      console.error("Singapore time is not set yet");
      return false;
    }

    // Use the fetched Singapore time
    let now = singaporeTime;
    let currentDate = isWeekView
      ? DateTime.fromJSDate(date).setZone("Asia/Singapore")
      : selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone("Asia/Singapore")
      : now;

    // If pitch is provided, add the pitch hours to the current time
    if (pitch) {
      const pitchHours = parseInt(pitch, 10);
      now = now.plus({ hours: pitchHours });
    }

    const [startTime, endTime] = slotTime.split("-").map((time) => time.trim());

    const slotStartTime = parseTimeToSingapore(startTime, currentDate);

    // Compare slotStartTime with the adjusted now time

    return slotStartTime < now;
  };

  function formatTimeRange(startTime, endTime) {
    let startParts = startTime.split(" ")[1].split(":");
    let startHour = parseInt(startParts[0]);
    let startMinutes = startParts[1];
    let startPeriod = startHour >= 12 ? "PM" : "AM";
    startHour = startHour % 12 || 12;

    let endParts = endTime.split(" ")[1].split(":");
    let endHour = parseInt(endParts[0]);
    let endMinutes = endParts[1];
    let endPeriod = endHour >= 12 ? "PM" : "AM";
    endHour = endHour % 12 || 12;

    let formattedStartTime = `${startHour}:${startMinutes} ${startPeriod}`;
    let formattedEndTime = `${endHour}:${endMinutes} ${endPeriod}`;
    let timeRange = `${formattedStartTime}-${formattedEndTime}`;

    return timeRange;
  }

  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    if (!timeToCheck) {
      return false;
    }
    if (availableRangeEnd.startsWith("23:59")) {
      availableRangeEnd = "24:00:00";
    }
    // Helper function to convert time in "HH:MM:SS" format to minutes since midnight
    const convert24HourToMinutes = (time) => {
      let [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    };

    // Helper function to convert time in "HH:MM AM/PM" format to minutes since midnight
    const convert12HourToMinutes = (time) => {
      let [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    };

    // Convert available range start and end to minutes since midnight
    const availableStart = convert24HourToMinutes(availableRangeStart);
    const availableEnd = convert24HourToMinutes(availableRangeEnd);

    // Extract and convert start and end times from the time range input to minutes since midnight
    const [startTime, endTime] = timeToCheck.split("-").map((t) => t.trim());
    const startMinutes = convert12HourToMinutes(startTime);
    const endMinutes = convert12HourToMinutes(endTime);

    // Handle case where end time is before start time (crossing midnight)

    const adjustedEndMinutes =
      endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

    // Check if the start and end times fall within the available range
    const fitsInRange =
      startMinutes >= availableStart && adjustedEndMinutes <= availableEnd;

    return fitsInRange;
  }
  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  return (
    <div className="flex flex-col">
      {newTimeWithslots?.map((slot, blockIndex) => {
        // console.log(selectedPitch,extractedWord, "selectedPitch");

        const timeRange = formatTimeRangespace(slot.range);
        let isSlotAvailable = false;

        for (
          let pitchIndex = 0;
          pitchIndex < visiblePitches.length;
          pitchIndex++
        ) {
          if (
            SelectedPitchName.consecutive_pitch === 1 &&
            pitchIndex % 3 !== 0
          ) {
            continue;
          }

          // console.log("hlo",pitchIndex)
          const pitch = visiblePitches[pitchIndex];
          const isPitchAvailableday =
            pitchWeekAvailability[getDayOfWeek(selectedDate)];
          const isDisabledbyTodayTime = isSlotDisabledByTodayTime(
            timeRange,
            pitch.booking_allowed_after_hrs,
            false,
            selectedDate
          );
          const isDisabled =
            isDisabledbyTodayTime ||
            newTimeWithslots[blockIndex]?.type === "gap" ||
            (isPitchAvailableday &&
              !isPitchAvailableday.some((slot) =>
                checkIfTimeFitsInRangeoforDay(
                  slot.start_time,
                  slot.end_time,
                  timeRange
                )
              ));

          if (!isDisabled) {
            const isBooked = isSlotAlreadyBooked(pitch, timeRange);
            if (!isBooked) {
              isSlotAvailable = true;
              break;
            }
          }
        }

        const backgroundColor = isSlotAvailable ? "white" : "lightgrey";
        const borderColor = isSlotAvailable ? "#48c14c" : "#8F8F8F";
        const filter = isSlotAvailable ? "" : "brightness(90%) saturate(0%)";
        // {console.log(timeRange,"sahil time issue")}

        return (
          <div key={`timeBlock-${blockIndex}`} className="pitch-box-container ">
            <div
              style={{
                backgroundColor,
                borderColor,
                cursor: "pointer",
              }}
              onClick={() => {
                if (isSlotAvailable) {
                  handleBoxClick("", timeRange, false, selectedDate);
                }
              }}
              className="pitch-box-style"
            >
              <img
                src={selectedPitchIcon}
                alt="Pitch icon"
                style={{
                  filter,
                  width: "30px",
                  height: "30px",
                }}
                className="my-2"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Compact;
