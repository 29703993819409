import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../styles.css";

import Footer from "../component/common/Footer";

import SignupPage from "../screens/AuthScreen/Signup";
import ForgotPassword from "../screens/AuthScreen/ForgotPassword";
import SignIn from "../screens/AuthScreen/SignIn";
import VerificationPage from "../screens/AuthScreen/VerificationPage";

import MyAccountPage from "../screens/MyAccountScreen/Myaccount";

import Venue from "../screens/VenueScreen/VenueListPage";
import VenueDetails from "../screens/VenueScreen/VenueDetailsPage";
import PitchFrames from "../screens/VenueScreen/PitchBooking";
import Accessories from "../screens/CartViewScreen/Accessories";
import Viewcart from "../screens/CartViewScreen/ViewCartPage";
import PaymentSuccessful from "../screens/PaymentScreen/SuccessfullPage";
import ItemDetailsPage from "../screens/CartViewScreen/ItemDetailsPage";
import Sign from "../screens/AuthScreen/sign";
import GoogleCallback from "../screens/AuthScreen/GoogleCallback";
import ScrollToTop from './Srolltotop'

// Define routes
const Routing = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/sign" element={<Sign />} />
          <Route path="/" element={<Venue />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />

          <Route path="/sign-in/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route
            path="/verification/email/:email/token/:token/fullname/:fullname"
            element={<VerificationPage />}
          />
          <Route
            path="/my-account"
            element={
              <MyAccountPage isOpen={isOpen} toggleSidebar={toggleSidebar} />
            }
          />
          <Route
            path="/venue-listing-page/venue/:name"
            element={<VenueDetails />}
          />
          <Route
            path="/venue-listing-page/venue/:venuenamefromurl/booking-page/:event"
            element={<PitchFrames />}
          />
          <Route
            path="/venue-listing-page/venue/:name/booking-page/accessories"
            element={<Accessories />}
          />
          {/* <Route path='/venue-listing-page/venue/:name/booking-page/accessories/viewcart' element={<Viewcart/>} /> */}
          <Route path="/viewcart" element={<Viewcart />} />
          <Route
            path="/payment-successfull/:platform/:id"
            element={<PaymentSuccessful />}
          />
          <Route
            path="/item-detail/:item_category/:name"
            element={<ItemDetailsPage />}
          />
        </Routes>
        <Footer />
      </Router>
      <ToastContainer />
    </>
  );
};

export default Routing;
