import axios from "axios";
import {
  verify,
  ForgottPassword,
  Login,
  VerifyEmail,
  SendOTP,
  VerifyOtp,
  SignUp,
  glogin,
  getClientid,
} from "../common/api";

export const forgotPassword = async (user) => {
  try {
    const response = await axios.post(ForgottPassword, { user });
    return response.data;
  } catch (error) {
    throw new Error(
      "Failed to send Forgot Password request. Please try again."
    );
  }
};

export const signIn = async (usr, pwd) => {
  try {
    const response = await fetch(Login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usr: usr,
        pwd: pwd,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to sign in");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Error signing in. Please Give me valid Password");
  }
};

export const verifyEmail = async (email, fullName) => {
  try {
    const response = await axios.post(VerifyEmail, {
      email: email,
      verf_link: verify,
      full_name: fullName,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to send verification email:", error);
    throw error;
  }
};

export const sendOTP = async (phone, otp) => {
  try {
    const response = await axios.post(SendOTP, {
      phone: `${phone}`,
      otp: otp,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to send OTP:", error);
    throw error;
  }
};

export const verifyOTP = async (phone, otp) => {
  try {
    const response = await axios.post(VerifyOtp, {
      phone: phone,
      otp: otp,
    });
    return response;
    // Optionally return the response data
  } catch (error) {
    console.error("OTP verification failed:", error);
    throw new Error("OTP verification failed. Please try again.");
  }
};

export const GLogin = async (obj) => {
  try {
    const response = await axios.post(glogin, obj);
    return response;
    // Optionally return the response data
  } catch (error) {
    console.error("google login failed", error);
    throw new Error("google login failed.");
  }
};

export const getClientId = async () => {
  try {
    const response = await axios.post(getClientid, {
      provider: "google",
    });
    return response;
    // Optionally return the response data
  } catch (error) {
    console.error("google login failed", error);
    throw new Error("google login failed.");
  }
};

export const signUp = async (email, phone, fullName) => {
  try {
    const response = await axios.post(SignUp, {
      email: email,
      phone: phone,
      full_name: fullName,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
