import React from "react";
import Banner from "../../assets/images/SignInBanner.png";

function RightBanner() {
  return (
    <div className="image-container">
      <img src={Banner} alt="Sign In Banner" className="SignUp-image" />
    </div>
  );
}

export default RightBanner;
