import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../handlers/authentication/api";
import Navbar from "../../component/common/Navbar";
import Logo from "../../assets/images/company_logo_cricket.png";
import forgotPasswordImage from "../../assets/images/SignInBanner.png"; // Import your image here
import RightBanner from "./RightBanner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setIsFormValid(isEmailValid);
  }, [email]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please enter a valid email");
      return;
    }

    try {
      await forgotPassword(email);
      console.log("Forgot Password request sent successfully");
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to send Forgot Password request:", error);
      setError(error.message);
    }
  };
  const handleRedirect = () => {
    navigate("/");
  };
  return (
    <>
      <section className="min-h-screen flex items-stretch justify-center signup">
        <div className="app-container">
          <div className="w-full signup-form-container p-10">
            <div className="signUpLogo">
              <img
                src={Logo}
                alt="Logo"
                className="top-0 left-0"
                onClick={handleRedirect}
              />
            </div>
            <div className="form-body xl:p-20">
              <h2 className="text-2xl font-bold leading-tight text-black">
                {" "}
                Forgot Password
              </h2>
              <p>
                Enter your email to receive a password reset link. 
                Check your email for the link and follow the instructions to reset your password.
              </p>
              {submitted ? (
                <p className="text-green-500 mb-4">
                  Please check the email address {email} <br />
                  for link to reset your password.
                </p>
              ) : (
                <>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-indigo-500"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={!isFormValid}
                      className={`inline-flex w-full items-center justify-center rounded-md px-3.5 py-2.5 font-semibold leading-7 text-white redBtn hover:bg-black/80 ${
                        !isFormValid && "opacity-50 cursor-not-allowed"
                      }`}
                    >
                      Continue
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
          <RightBanner />
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
