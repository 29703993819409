export const DateToDays = (futuredays) => {
    const date_in_milisec = new Date(futuredays)-new Date();
    const millisInADay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(date_in_milisec / millisInADay);
    if(differenceInDays<=6){
      return 0
    }
    if(differenceInDays%2===0){
      return Math.floor(differenceInDays/6)
    }else{
      return Math.floor((differenceInDays-1)/6)
    }
  }
  