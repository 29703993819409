import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../component/common/NavbarProfile";
import Profile from "./Profile";
import Bookings from "./Bookings";
import PaymentMethods from "./PaymentMethods";
import NotificationSettings from "./NotificationSettings";
import { useNavigate } from "react-router-dom";
import My_Profile from "../../assets/images/My_Profile.png";
import Booking from "../../assets/images/Booking.png";
import Notification from "../../assets/images/Notifications.png";
import Payment from "../../assets/images/Payment.png";
import Logout from "../../assets/images/Logout.png";

const MyAccount = ({ toggleSidebar, isOpen }) => {
  const [selectedSection, setSelectedSection] = useState("profile");
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleSectionChange = (section) => {
    setSelectedSection(section);
    toggleSidebar();
  };

  const renderSection = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "bookings":
        return <Bookings />;
      case "paymentMethods":
        return <PaymentMethods />;
      case "notificationSettings":
        return <NotificationSettings />;
      default:
        return null;
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cartItems");
    


    navigate("/");
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="flex max-w-full py-4 px-2 account-page">
        {/* Sidebar */}
        {(isOpen || !isMobile) && (
          <div
            className={`h-full bg-white text-black transition-all duration-300 account-detail ${
              isOpen || !isMobile ? "translate-x-0" : "-translate-x-full"
            } ${!isMobile ? "w-1/6 pr-10" : "px-2"}`}
            style={{ width: isMobile ? "100%" : "auto" }}
          >
            <ul className="pl-0">
              <li className="mb-2">
                <div
                  onClick={() => handleSectionChange("profile")}
                  className={`${
                    selectedSection === "profile"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 flex  focus:outline-none font-class text-left items-center p-2 pl-4`}
                >
                  <img
                    src={My_Profile}
                    alt="Profile icon"
                    className=" px-2"
                  />
                  <span>My Profile</span>
                </div>
              </li>
              <li className="mb-2">
                <div
                  onClick={() => handleSectionChange("bookings")}
                  className={`${
                    selectedSection === "bookings"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 flex hover:text-blue-700 focus:outline-none font-class text-left items-center p-2 pl-4`}
                >
                  <img
                    src={Booking}
                    alt="Booking icon"
                    className=" px-2" 
                  />
                  Bookings
                </div>
              </li>
              <li className="mb-2">
                <div
                  onClick={() => handleSectionChange("notificationSettings")}
                  className={`${
                    selectedSection === "notificationSettings"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 flex focus:outline-none font-class text-left items-center p-2 pl-4`}
                >
                  <img
                    src={Notification}
                    alt="Notification icon"
                    className="mr-1 px-2" 
                  />
                  Notification Settings
                </div>
              </li>

              <li className="mb-2">
                <div
                  onClick={() => handleSectionChange("paymentMethods")}
                  className={`${
                    selectedSection === "paymentMethods"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 flex focus:outline-none font-class text-left items-center p-2 pl-4`}
                >
                  <img
                    src={Payment}
                    alt="Notification icon"
                    className=" px-2" 
                  />
                  Payment Methods
                </div>
              </li>

              {token && ( // Only render the logout button if token exists
                <li className="mb-2">
                  <div
                    onClick={handleLogout}
                    className={`${
                      selectedSection === "logout"
                        ? "bg-red-100 rounded-full"
                        : ""
                    } text-black-500 hover:text-blue-700 flex focus:outline-none font-class text-left items-center p-2 pl-4`}
                  >
                    <img
                      src={Logout}
                      alt="Logout icon" 
                      className="px-2 h-6"
                    />
                    Logout
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
        {/* Main Content */}
        {(!isOpen || !isMobile) && (
          <div className="flex-1">{renderSection()}</div>
        )}
      </div>
    </>
  );
};

export default MyAccount;
