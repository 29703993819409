import React, { useState } from "react";
import PitchBlock from "./PitchBlock.js";
import { useEffect } from "react";
import { DateTime } from "luxon";

const AllPitches = ({
  visiblePitches,
  newTimeWithslots,
  selectedPitchIcon,
  selectedDisplaypitche,
  isSlotSelectedInCart,
  isSlotDeleatable,
  handleBoxClick,
  removeFromCart,
  selectedDate,
  bookedPitches,
  pitchWeekAvailability,
  selectedPitchName,
  singaporeTime,
}) => {
  //useEffect for console.logs()
  useEffect(() => {
    console.log("sample", visiblePitches);
  });

  const formatTimeRangespace = (timeRange) => {
    if (timeRange) {
      const insertSpace = (time) => {
        return time.replace(/([AP]M)/, " $1");
      };

      const [start, end] = timeRange.split("-");
      const formattedStart = insertSpace(start);

      const formattedEnd = insertSpace(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const isSlotAlreadyBooked = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return false;
    let temp = bookedPitches.some((item) => {
      const isSamePitch = item.custom_pitch === pitch.name;

      const isSameDate =
        formatDate(item.starts_on.split(" ")[0]) ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(
        time,
        formatTimeRange(item.starts_on, item.ends_on)
      );
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  function formatDate(date) {
    if (!date) {
      return "";
    }

    if (!(date instanceof Date)) {
      // Attempt to create a Date object from the input
      date = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string if date is invalid
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[monthIndex]} ${year}`;
  }

  const isTimeInRange = (time, range) => {
    if (!range) return false;
    // Parse the time values as hours, minutes, and meridiem (AM/PM)
    const timeMatch = time.match(/(\d+):(\d+) (AM|PM)/);

    if (timeMatch === null) {
      return false;
    }

    const [selectedStartHour, selectedStartMinute, selectedStartMeridiem] =
      timeMatch.slice(1);
    const [slotStartHour, slotStartMinute, slotStartMeridiem] = range
      .split("-")[0]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [slotEndHour, slotEndMinute, slotEndMeridiem] = range
      .split("-")[1]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    // Convert hours to 24-hour format if necessary
    const convertTo24Hour = (hour, meridiem) => {
      if (hour === "12") {
        return meridiem === "AM" ? 0 : 12;
      }
      return parseInt(hour) + (meridiem === "PM" ? 12 : 0);
    };

    const selectedHour = convertTo24Hour(
      selectedStartHour,
      selectedStartMeridiem
    );
    const slotStart = convertTo24Hour(slotStartHour, slotStartMeridiem);
    const slotEnd = convertTo24Hour(slotEndHour, slotEndMeridiem);

    // Convert minutes to integers
    const selectedMinute = parseInt(selectedStartMinute);
    const slotStartMinuteInt = parseInt(slotStartMinute);
    const slotEndMinuteInt = parseInt(slotEndMinute);

    // Calculate the total minutes for the selected time
    const selectedTotalMinutes = selectedHour * 60 + selectedMinute;

    // Check if the selected time falls within the range
    if (slotStart <= slotEnd) {
      return (
        selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
        selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt
      );
    } else {
      // If the range spans across AM/PM, consider it as two separate ranges
      return (
        (selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
          selectedTotalMinutes < 24 * 60) ||
        (selectedTotalMinutes >= 0 &&
          selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt)
      );
    }
  };

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  function formatTimeRange(startTime, endTime) {
    // Convert start time to 12-hour format
    let startParts = startTime.split(" ")[1].split(":");
    let startHour = parseInt(startParts[0]);
    let startMinutes = startParts[1];
    let startPeriod = startHour >= 12 ? "PM" : "AM";
    startHour = startHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Convert end time to 12-hour format
    let endParts = endTime.split(" ")[1].split(":");
    let endHour = parseInt(endParts[0]);
    let endMinutes = endParts[1];
    let endPeriod = endHour >= 12 ? "PM" : "AM";
    endHour = endHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Construct the formatted time range string
    let formattedStartTime = `${startHour}:${startMinutes} ${startPeriod}`;
    let formattedEndTime = `${endHour}:${endMinutes} ${endPeriod}`;
    let timeRange = `${formattedStartTime}-${formattedEndTime}`;

    return timeRange;
  }
  const parseTimeToSingapore = (timeStr, referenceDate) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return referenceDate.set({ hour: hours, minute: minutes, second: 0 });
  };

  const isSlotDisabledByTodayTime = (
    slotTime,
    pitch,
    isWeekView = false,
    date = null
  ) => {
    if (!singaporeTime) {
      console.error("Singapore time is not set yet");
      return false;
    }

    // Use the fetched Singapore time
    let now = singaporeTime;
    let currentDate = isWeekView
      ? DateTime.fromJSDate(date).setZone("Asia/Singapore")
      : selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone("Asia/Singapore")
      : now;

    // If pitch is provided, add the pitch hours to the current time
    if (pitch) {
      const pitchHours = parseInt(pitch, 10);
      now = now.plus({ hours: pitchHours });
    }

    const [startTime, endTime] = slotTime.split("-").map((time) => time.trim());

    const slotStartTime = parseTimeToSingapore(startTime, currentDate);

    // Compare slotStartTime with the adjusted now time

    return slotStartTime < now;
  };
  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    if (!timeToCheck) {
      return false;
    }
    if (availableRangeEnd.startsWith("23:59")) {
      availableRangeEnd = "24:00:00";
    }
    // Helper function to convert time in "HH:MM:SS" format to minutes since midnight
    const convert24HourToMinutes = (time) => {
      let [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    };

    // Helper function to convert time in "HH:MM AM/PM" format to minutes since midnight
    const convert12HourToMinutes = (time) => {
      let [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    };

    // Convert available range start and end to minutes since midnight
    const availableStart = convert24HourToMinutes(availableRangeStart);
    const availableEnd = convert24HourToMinutes(availableRangeEnd);

    // Extract and convert start and end times from the time range input to minutes since midnight
    const [startTime, endTime] = timeToCheck.split("-").map((t) => t.trim());
    const startMinutes = convert12HourToMinutes(startTime);
    const endMinutes = convert12HourToMinutes(endTime);

    // Handle case where end time is before start time (crossing midnight)

    const adjustedEndMinutes =
      endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

    // Check if the start and end times fall within the available range
    const fitsInRange =
      startMinutes >= availableStart && adjustedEndMinutes <= availableEnd;

    return fitsInRange;
  }

  return (
    <div>
      {[...Array(newTimeWithslots?.length)].map((_, blockIndex) => (
        <div key={blockIndex} className="flex">
          {visiblePitches.map((pitch, pitchIndex) => {
            const timeRange = formatTimeRangespace(
              newTimeWithslots[blockIndex]?.range
            );
            const alreadyBooked = isSlotAlreadyBooked(pitch, timeRange);
            const isPitchAvailableday =
              pitchWeekAvailability[getDayOfWeek(selectedDate)];
            const isDisabledbyTodayTime = isSlotDisabledByTodayTime(
              timeRange,
              pitch.booking_allowed_after_hrs,
              false,
              selectedDate
            );

            const isPitchAvailable =
              isDisabledbyTodayTime ||
              newTimeWithslots[blockIndex]?.type === "gap" ||
              (isPitchAvailableday &&
                !isPitchAvailableday.some((slot) =>
                  checkIfTimeFitsInRangeoforDay(
                    slot.start_time,
                    slot.end_time,
                    formatTimeRangespace(newTimeWithslots[blockIndex]?.range)
                  )
                ));
            const is8A = selectedPitchName?.consecutive_pitch;

            return (
              selectedDisplaypitche === "All Pitches" && (
                <PitchBlock
                  key={`${pitchIndex}-${blockIndex}`}
                  blockIndex={blockIndex}
                  pitch={pitch}
                  time={newTimeWithslots[blockIndex]?.range}
                  pitchIndex={pitchIndex}
                  timeRange={timeRange}
                  isSlotSelectedInCart={isSlotSelectedInCart}
                  alreadyBooked={alreadyBooked}
                  isPitchAvailable={isPitchAvailable}
                  isSlotDeleatable={isSlotDeleatable}
                  is8A={is8A}
                  selectedPitchIcon={selectedPitchIcon}
                  handleBoxClick={handleBoxClick}
                  removeFromCart={removeFromCart}
                  selectedDate={selectedDate}
                />
              )
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default AllPitches;
