import React from 'react';

const PitchBlock = ({
  blockIndex,
  pitch,
  time,
  pitchIndex,
  timeRange,
  isSlotSelectedInCart,
  alreadyBooked,
  isPitchAvailable,
  isSlotDeleatable,
  is8A,
  selectedPitchIcon,
  handleBoxClick,
  removeFromCart,
  selectedDate
}) => {




    function formatDate(date) {
        if (!date) {
          return "";
        }
    
        if (!(date instanceof Date)) {
          // Attempt to create a Date object from the input
          date = new Date(date);
        }
    
        // Check if the date is valid
        if (isNaN(date.getTime())) {
          return ""; // Return an empty string if date is invalid
        }
    
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return `${day} ${months[monthIndex]} ${year}`;
      }
  return (
    <div
      key={`${pitchIndex}-${blockIndex}`}
      style={{
        backgroundColor: isSlotSelectedInCart(pitch, timeRange)
          ? 'green'
          : alreadyBooked || isPitchAvailable
            ? 'lightgray'
            : 'white',
        borderColor:
          alreadyBooked || isPitchAvailable
            ? '#8F8F8F'
            : '#48c14c',
        cursor: isSlotDeleatable(pitch, timeRange)
          ? 'pointer'
          : isSlotSelectedInCart(pitch, timeRange) ||
            alreadyBooked ||
            isPitchAvailable ||
            (is8A && pitchIndex % 3 !== 0)
            ? 'not-allowed'
            : 'pointer',
      }}
      onClick={() => {
        if (
          alreadyBooked ||
          isPitchAvailable ||
          (is8A && pitchIndex % 3 !== 0)
        ) {
          return;
        }
        if (
          isSlotSelectedInCart(pitch, timeRange) &&
          isSlotDeleatable(pitch, timeRange)
        ) {
          removeFromCart(pitch.name, timeRange, formatDate(selectedDate));
        } else if (!isSlotSelectedInCart(pitch, timeRange)) {
          console.log("handlebox",pitch,timeRange)
          handleBoxClick(pitch, timeRange,false,selectedDate);
        }
      }}
      className="pitch-box-style"
    >
      <img
        src={selectedPitchIcon}
        alt="Pitch icon"
        style={{
          filter: isSlotSelectedInCart(pitch, timeRange)
            ? 'hue-rotate(180deg) brightness(200%)'
            : alreadyBooked || isPitchAvailable
              ? 'brightness(90%) saturate(0%)'
              : '',
          width: '30px',
          height: '30px',
        }}
      />
    </div>
  );
};

export default PitchBlock;










